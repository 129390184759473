import {
  Box,
  Flex,
  GridItem,
  GridProps,
  Link,
  Spacer,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { FC, PropsWithChildren, useState } from 'react'

import { AppFooter } from '~/app/Footer/AppFooter'
import { ReactComponent as LoginImage } from '~assets/LandingLoginImage.svg'
import { ReactComponent as PinpointLogo } from '~assets/pinpoint-logo.svg'
import { useIsDesktop } from '~hooks/useIsDesktop'
import { useAuth } from '~lib/auth'
import {
  CONTACT_PINPOINT_URL,
  PINPOINT_FAQ_URL,
  PINPOINT_GUIDE_URL,
} from '~shared/constants/links'
import { AppGrid } from '~templates/AppGrid'

import { LoginForm, LoginFormInputs } from '../components/LoginForm'
import { OtpForm, OtpFormInputs } from '../components/OtpForm'

export type LoginOtpData = {
  email: string
}

// Component for the split blue/white background.
const BackgroundBox: FC<PropsWithChildren> = ({ children }) => (
  <Flex
    flex={1}
    overflow={{ lg: 'auto' }}
    flexDir="column"
    h="inherit"
    bgGradient={{
      md: 'linear(to-b, base.canvas.default 20.5rem, base.canvas.alt 0)',
      lg: 'linear(to-r, base.canvas.default calc(41.6667% - 4px), base.canvas.alt 0)',
    }}
  >
    {children}
  </Flex>
)

// Component that controls the various grid areas according to responsive breakpoints.
const BaseGridLayout = (props: GridProps) => (
  <AppGrid templateRows={{ md: 'auto 1fr auto', lg: '1fr auto' }} {...props} />
)

// Grid area styling for the login form.
const LoginGridArea: FC<PropsWithChildren> = ({ children }) => (
  <GridItem
    gridColumn={{ base: '1 / 5', md: '2 / 12', lg: '7 / 12' }}
    py="4rem"
    display="flex"
    alignItems={{ base: 'initial', lg: 'center' }}
    justifyContent="center"
  >
    {children}
  </GridItem>
)

// Grid area styling for the footer.
const FooterGridArea: FC<PropsWithChildren> = ({ children }) => (
  <GridItem
    alignSelf="end"
    gridColumn={{ base: '1 / 5', md: '2 / 12' }}
    p={{ base: '2.5rem 0', lg: '0 0 0 2.5rem' }}
    bg={{ base: 'base.canvas.brandLight', lg: 'transparent' }}
  >
    {children}
  </GridItem>
)

// Grid area styling for the left sidebar that only displays on tablet and desktop breakpoints.
const NonMobileSidebarGridArea: FC<PropsWithChildren> = ({ children }) => (
  <GridItem
    display={{ base: 'none', md: 'flex' }}
    gridColumn={{ md: '1 / 13', lg: '1 / 6' }}
    h={{ md: '20.5rem', lg: 'auto' }}
    pt={{ base: '1.5rem', md: '2.5rem', lg: '3rem' }}
    pb={{ lg: '3rem' }}
    flexDir="column"
    alignItems={{ base: 'center', lg: 'center' }}
    justifyContent="center"
  >
    {children}
  </GridItem>
)

export const LoginPage = (): JSX.Element => {
  const { sendLoginOtp, verifyLoginOtp } = useAuth()
  const [email, setEmail] = useState<string>()
  const [resendOtpCountdown, setResendOtpCountdown] = useState<number>(60)
  const isMobile = !useIsDesktop()

  const handleSendOtp = async ({ email }: LoginFormInputs) => {
    const cleanedEmail = email.trim().toLowerCase()
    const { nextOtpRequestAt } = await sendLoginOtp({ email: cleanedEmail })

    if (nextOtpRequestAt) {
      const timeUntilNextRequest = Math.ceil(
        (new Date(nextOtpRequestAt).getTime() - Date.now()) / 1000,
      )
      setResendOtpCountdown(timeUntilNextRequest)
    }
    return setEmail(cleanedEmail)
  }

  const resetEmail = () => setEmail(undefined)

  const handleVerifyOtp = async ({ token }: OtpFormInputs) => {
    // Should not happen, since OtpForm component is only shown when there is
    // already an email state set.
    if (!email) {
      throw new Error('Something went wrong')
    }
    return verifyLoginOtp({ token, email })
  }

  const handleResendOtp = async () => {
    // Should not happen, since OtpForm component is only shown when there is
    // already an email state set.
    if (!email) {
      throw new Error('Something went wrong')
    }
    await sendLoginOtp({ email })
  }

  return (
    <BackgroundBox>
      <BaseGridLayout minHeight="100vh">
        <NonMobileSidebarGridArea>
          <LoginImage width="100%" aria-hidden />
        </NonMobileSidebarGridArea>
        <LoginGridArea>
          <Flex
            direction="column"
            h="80vh"
            minH={{ base: '25rem', lg: '17.25rem' }}
            w="100%"
          >
            <Flex mb={{ base: '2.5rem', lg: 0 }} flexDir="column">
              {!isMobile ? (
                <Text
                  textStyle="responsive-heading.heavy-1280"
                  mb="2.5rem"
                  textColor="base.content.strong"
                >
                  Streamline media organisation for Government
                </Text>
              ) : (
                <Box>
                  <Box mb={{ base: '0.75rem', lg: '1.5rem' }}>
                    <PinpointLogo width="11rem" height="2.75rem" />
                  </Box>
                  <Text
                    textStyle={{
                      base: 'responsive-heading.heavy',
                      md: 'responsive-heading.heavy-480',
                      lg: 'responsive-heading.heavy-1280',
                    }}
                    fontSize="1.125rem"
                    textColor="base.content.strong"
                    lineHeight="1.5rem"
                  >
                    Streamline media organisation for Government
                  </Text>
                </Box>
              )}
            </Flex>
            {!email ? (
              <LoginForm onSubmit={handleSendOtp} />
            ) : (
              <OtpForm
                email={email}
                onSubmit={handleVerifyOtp}
                onResendOtp={handleResendOtp}
                resendOtpCountdown={resendOtpCountdown}
                resetEmail={resetEmail}
              />
            )}
            <Spacer />
            <Wrap spacing="1.5rem" justifyItems="end" justify="end">
              <WrapItem>
                <Link isExternal href={PINPOINT_FAQ_URL} textDecoration="none">
                  Does my work allow Pinpoint?
                </Link>
              </WrapItem>
              <WrapItem>
                <Link
                  isExternal
                  href={PINPOINT_GUIDE_URL}
                  textDecoration="none"
                >
                  Guide
                </Link>
              </WrapItem>
              <WrapItem>
                <Link
                  isExternal
                  href={CONTACT_PINPOINT_URL}
                  textDecoration="none"
                >
                  Contact us
                </Link>
              </WrapItem>
            </Wrap>
          </Flex>
        </LoginGridArea>
      </BaseGridLayout>
      <BaseGridLayout
        bg={{ base: 'base.canvas.alt', lg: 'base.canvas.default' }}
      >
        <FooterGridArea>
          <AppFooter />
        </FooterGridArea>
      </BaseGridLayout>
    </BackgroundBox>
  )
}
