export const CONTACT_PINPOINT_URL = 'https://go.gov.sg/contact-pinpoint'
export const CONTACT_PINPOINT_URL_SHORTENED = 'go.gov.sg/contact-pinpoint'
export const PINPOINT_GUIDE_URL = 'https://go.gov.sg/pinpoint-guide'
export const PINPOINT_PRIVACY_URL = 'https://go.gov.sg/pinpoint-privacy'
export const PINPOINT_TOU_URL = 'https://go.gov.sg/pinpoint-tou'
export const GOVTECH_REPORT_VULNERABILITY_URL =
  'https://go.gov.sg/report-vulnerability'
export const PINPOINT_FAQ_URL = 'https://go.gov.sg/faqs-pinpoint'
export const PINPOINT_ROLE_FAQ_URL = 'https://go.gov.sg/pinpoint-role-faq'
export const PINPOINT_TELEGRAM_COMMUNITY_URL =
  'https://go.gov.sg/pinpoint-community'

export const DIS_WEBSITE_URL = 'https://www.mindef.gov.sg/dis'
export const DOTC_WEBSITE_URL = 'https://dotc.gov.sg'
export const OGP_WEBSITE_URL = 'https://open.gov.sg'
export const PINPOINT_URL = 'https://app.pinpoint.gov.sg'

export const DIS_LINKEDIN =
  'https://sg.linkedin.com/company/digital-and-intelligence-service'
export const DIS_INSTAGRAM = 'https://www.instagram.com/thesingaporedis/'
export const DIS_FACEBOOK = 'https://www.facebook.com/thesingaporeDIS/'

//images for emails
export const PINPOINT_LOGO_WHITE = 'https://file.go.gov.sg/pplogo-white.png'
export const DOTC_LOGO = 'https://file.go.gov.sg/dotc-logo.png'
export const ADDED_TO_WORKSPACE_IMAGE =
  'https://file.go.gov.sg/pp-join-team-image.png'
export const DELETED_FROM_WORKSPACE_IMAGE =
  'https://file.go.gov.sg/pp-remove.png'
export const WORKSPACE_DELETED_IMAGE = 'https://file.go.gov.sg/ppdeletedws.png'
